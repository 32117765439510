import React from 'react'

const Particles = () => (
<div id="particle-container">
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    <div className="particle"></div> 
    </div>
    
)

export default Particles